import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

let logoStyle = {
  width: '640px',
  height: 'auto',
  cursor: 'pointer',
  alignSelf: 'center',
};

const mobileQuery = window.matchMedia('(max-width: 768px)'); // Adjust the max-width as needed

function handleMobileScreenChange(mobileQuery) {
  const updatedLogoStyle = Object.assign({}, logoStyle); // Create a copy of logoStyle

  if (mobileQuery.matches) {
    updatedLogoStyle.width = '90%'; // Set the width to 50% for mobile screens
    alignSelf: 'center';
  } else {
    updatedLogoStyle.width = '640px'; // Set the original width for screens wider than 768px
    alignSelf: 'center';
  }

  logoStyle = updatedLogoStyle; // Update logoStyle with the modified style
}

mobileQuery.addListener(handleMobileScreenChange); // Call the function when the screen size changes initially

// Call the function once initially to apply styles based on the initial screen width
handleMobileScreenChange(mobileQuery);
export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : 'linear-gradient(#02294F, #090E10)',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            <img
                src={
                  '/LogoFontWhite.png'
                }
                style={logoStyle}
                alt="logo of Majesta"
              />
          </Typography>
        </Stack>
        <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 200, sm: 300 },
            width: '100%',
            backgroundImage:
              theme.palette.mode === 'dark'
                ? 'url("/public/MajestaBlackLogo.png")'
                : 'url("/public/MajestaBlackLogo.png)',
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          })}
        />
      </Container>
    </Box>
  );
}
